<template>
  <div class="divider">
    <!-- <v-divider class="divider-above"></v-divider> -->
    <v-divider class="divider-bellow"></v-divider>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/styles/main.scss";

.divider {
  margin-top: 60px;
  margin-bottom: 40px;
}

.divider-above {
  width: 28.5%;
  border: 3px solid $dark !important;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
}

.divider-bellow {
  position: relative;
  border: 3px solid $inactive_grey !important;
  border-radius: 8px;
}
</style>
