<template>
  <div class="phase3">
    <vue-gallery
      id="gallery-phase3-new"
      :images="images"
      :index="index"
      @close="index = null"
    ></vue-gallery>
    <div>
      <div class="bsat__title">{{ $t("title") }}</div>
      <section>
        <div>
          <i18n path="paragraph.text" tag="div" class="bsat__paragraph">
            <template #link1>
              <a @click="goToTab(1)">{{ $t("paragraph.link1") }}</a>
            </template>
            <template #link2>
              <a @click="goToTab(2)">{{ $t("paragraph.link2") }}</a>
            </template>
            <template #link3>
              <a @click="goToTab(3)">{{ $t("paragraph.link3") }}</a>
            </template>
          </i18n>
        </div>

        <div class="bsat__paragraph">{{ $t("paragraph.subparagraph") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph.subparagraph2") }}</div>
      </section>

      <section>
        <div class="bsat__subtitle">{{ $t("subtitle") }}</div>
        <div class="bsat__paragraph">{{ $t("paragraph1") }}</div>
        <ReadMore>
          <div class="bsat__paragraph">{{ $t("paragraph2") }}</div>

          <i18n path="orderedListTitle.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("orderedListTitle.title") }}</strong>
            </template>
          </i18n>
          <div class="mb-4">
            <ol>
              <li v-for="item in orderedList" :key="item.title" class="mb-2">
                {{ item.title }}
                <ul
                  v-if="item.children"
                  class="mt-2"
                  style="list-style-type: lower-alpha"
                >
                  <li
                    class="bsat__paragraph mb-2"
                    v-for="(child, i) in getChildren(item)"
                    :key="i"
                    v-text="child"
                  ></li>
                </ul>
              </li>
            </ol>
          </div>
          <div class="bsat__paragraph">{{ $t("paragraph3") }}</div>
        </ReadMore>
      </section>
    </div>

    <!-- ***************** STEPS ****************** -->
    <div class="steps pt-10">
      <div class="bsat__subtitle">{{ $t("stepsTitle") }}</div>
      <v-stepper class="elevation-0 bg-off_white" v-model="steps">
        <!-- ************* STEPPER HEADER **************** -->
        <v-stepper-header
          :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '50%' : '' }"
        >
          <v-stepper-step @click="steps = 1" step="1"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step @click="steps = 2" step="2"> </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step @click="steps = 3" step="3"> </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step @click="steps = 4" step="4"> </v-stepper-step>

          <v-divider></v-divider>
          <v-stepper-step @click="steps = 5" step="5"> </v-stepper-step>
        </v-stepper-header>

        <!-- ***************** STEPPER CONTENT ***********************-->
        <v-stepper-content
          v-for="(step, i) in phase3"
          :key="i"
          :step="i + 1"
          class="steps__content px-0"
        >
          <keep-alive>
            <component :is="step.component"></component>
          </keep-alive>
        </v-stepper-content>
      </v-stepper>
    </div>

    <Divider></Divider>

    <div class="bsat__subtitle">{{ $t("power4BioExperience") }}</div>

    <!--************************* TABS **************************** -->
    <div class="phase3__tabs">
      <v-tabs
        v-model="tab"
        class="bsat__tabs"
        background-color="transparent"
        slider-color="dark"
        color="dark"
        show-arrows
        center-active
      >
        <v-tab
          v-for="(item, i) in footerTabs"
          :key="i"
          class="mr-4 mr-sm-8"
          :style="
            $vuetify.breakpoint.xsOnly ? 'min-width: 90px' : 'min-width: 160px'
          "
        >
          {{ $t(item.tab) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="py-4">
        <!-- ********** REAL EXAMPLES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div class="bsat__paragraph">
              {{ $t("realExamples.paragraph") }}
            </div>

            <v-row v-for="(item, i) in examples" :key="i" align="center">
              <v-col cols="12" md="3">
                <v-img
                  class="elevation-2 rounded"
                  style="height: 200px !important"
                  :src="require(`@/assets/icons/regions/${item.icon}`)"
                  @click="index = i"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <div class="bsat__paragraph">
                  <strong>{{ item.title }}</strong>
                </div>
                <a :href="realExamplesFiles[i]" target="_blank" download>
                  <v-btn
                    class="bsat__btn"
                    color="dark"
                    dark
                    depressed
                    width="150"
                    height="26"
                  >
                    {{ $t("download") }}
                  </v-btn>
                </a>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <!-- ********** NOTES TAB ******* -->
        <v-tab-item>
          <div class="bg-off_white">
            <div>
              <i18n path="notes.lviv.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a target="_blank">{{ $t("notes.lviv.title") }}</a>
                </template>
              </i18n>
            </div>
            <div>
              <i18n path="notes.mazovia.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a target="_blank">{{ $t("notes.mazovia.title") }}</a>
                </template>
              </i18n>
            </div>

            <div>
              <i18n path="notes.bohemia.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a target="_blank">{{ $t("notes.bohemia.title") }}</a>
                </template>
              </i18n>
            </div>

            <div>
              <i18n path="notes.nitra.text" tag="div" class="bsat__paragraph">
                <template #title>
                  <a target="_blank">{{ $t("notes.nitra.title") }}</a>
                </template>
              </i18n>
            </div>
          </div>
        </v-tab-item>

        <!-- ********** OPINIONS TAB ******* -->
        <!-- <v-tab-item>
          <v-card flat color="off_white">
            <v-card-text class="px-0">
              <v-list dense class="bg-off_white">
                <v-list-item
                  dense
                  class="text-default"
                  v-for="(item, i) in currentOpinions"
                  :key="i"
                >
                  <v-list-item-avatar size="40">
                    <v-img src="@/assets/icons/profile.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.subtitle
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row>
                <v-spacer></v-spacer>
                <v-pagination
                  class="pagination"
                  color="dark"
                  v-model="page"
                  :length="2"
                ></v-pagination>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item> -->
      </v-tabs-items>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "notes": {
        "tabTitle": "Notes",
      "lviv": {
        "text": "Lviv: Online workshop within the frameworks of the cross-visit to the Lviv, Ukraine has confirmed assumption that in the condition of the lack of financing and poor awareness regarding bioeconomy development it will be reasonable to use the following directions of the regional bioeconomy strategy: knowledge and education; powerful promotional media company; raising attractiveness for the regional bioeconomy.",
        "title": "Lviv"
      },
      "mazovia": {
        "text": "Mazovia: We encourage the involvement and support of regional authorities in the development of the Bioeconomy Strategy to ensure the adoption of the initiative. When developing a bioeconomy strategy, you should focus on the most important industries that can have the greatest impact on the development of the bioeconomy.Even if the bioeconomy strategy document is quite general, the development of a roadmap should be associated with specific tasks, time schedule, budget and source of financing.",
        "title": "Mazovia"
      },
      "bohemia": {
        "text": "South Bohemia: We managed to find key subjects and arouse their interest in cooperation. At this time, the cooperation is already underway and interest in the topic is rather growing. Also, the attitudes of key partners to the issue of bioeconomics in the region were recognized. Lastly, we managed to gather background materials from the region on bioeconomics.",
        "title": "South Bohemia"
      },
      "nitra": {
        "text": "Nitra The process of strategy development in bioeconomy field in the POWER4BIO project has been overlapping with the process of development of the general strategy for socio-economic development of the Nitra region. This has helped us to make clear what is important in prioritized sectors as well as to define proposals of innovative financial tools based on experts´ recommendations, and based on collected examples of good policy examples. Also, ongoing bioeconomy projects and initiatives have learned us many things. One of the most important is that strategy development at all levels (international, national, regional) means making clear cut choices about how to compete with other regions and how to manage it in an atmosphere of cooperation",
        "title": "Nitra"
      }
    },
    "opinions": {
      "list": [
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 1"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 2"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 3"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 4"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 5"
        },
        {
          "subtitle": "To be included",
          "title": "Mr. Smith Smith Organization 6"
        }
      ],
      "title": "Opinions"
    },
    "orderedList": [
      {
        "children": [
          "The decision-level: policy-makers within involved ministries or regional departments. The ones appointed to this group have as role to take the main decisions and approve several aspects within the process. For instance, approve the designed participatory approach with regional stakeholders or revise and approve the draft of the strategy. ",
          "The technical-level: technical experts within ministries or regional departments, that will validate collected knowledge during participatory process and will draft the document of the strategy. This group will have constant communication with the decision-level for feedback and advice."
        ],
        "title": "Representatives from the formed Interministerial/interdepartmental group, which has previously signalized their agreement to work together towards the strategy development. Within this group is common to have two subgroups:"
      },
      {
        "title": "Advisory group: formed by experts of diverse areas, called by the Interministerial/interdepartmental group to enrich the process. Ideally the RBH should already have involved these experts."
      },
      {
        "title": "Managers for public consultation processes and communication chanels: group or institution in charge of designing and carrying out the participatory process with the supervision of the Interministerial/interdepartmental group. The RBH will be fundamental in this step, since it represents at this point a platform to reach several stakeholders of the regional bioeconomy community. "
      }
    ],
    "orderedListTitle": {
      "text": "Usually, the {title} comprise:  ",
      "title": "strategy development working group"
    },
    "paragraph": {
      "link1": "Phase 1",
      "link2": "Phase 2",
      "link3": "Phase 3",
      "text": "The development process could be adapted according to the respective regional starting conditions. It is assumed that the regions have completed all steps of Phase 1 and Phase 2. This will help the regions start Phase 3 with a good understanding of regional bioeconomy potential and status quo",
      "subparagraph": "Phase 3 finishes with the draft bioeconomy strategy after completing a number of actions/steps. This draft lays the foundation of new drafts of the bioeconomy strategy to come as it formulates the minimum information to include in such a document. Phase 4 will result in setting up the implementation phase of this strategy. By the end of Phase 4, the regions will have completed the whole process of defining and agreeing on a robust and supported bioeconomy strategy, including steps and actions to be undertaken in the future.",
      "subparagraph2": "The indicated steps below refer in some cases to the same resources. Those resources can be used at different stages and in view to the maturity of the region. All documents are made available in all the steps to facilitate the access to all the related documents at the same time."
    },
    "paragraph1": "The steps here described assume that a decision at regional policy level to go ahead with the strategy development has been achieved. Possibly through the leadership of the RBH and (regional) policy makers involved in it. A regional Interministerial or interdepartmental agreement can be an optional method to signalize the agreement and interest to develop the regional bioeconomy strategy. This mandate implies their ability to represent and communicate the decision to the national level and general public through usual dissemination channels on behalf of the regional bioeconomy community. ",
    "paragraph2": "Here it is important to differentiate between the team that will be coordinating and working directly on the strategy development process (hereafter called strategy development working group) and other involved stakeholders through the participatory process to hold into account their considerations, perceptions, interest and concerns during specific phases of the process. In whichever case, the group in charge of the bioeconomy strategy definition should be a recognised and skill group of experts and representatives from the main decision-making bodies with responsibilities in the bioeconomy field. ",
    "paragraph3": "Other stakeholders to be engaged within the participatory process of the strategy development are mostly those involved in the RBH. However, in case there is any missing key stakeholder not participating in the  RBH before, would be identified, and hopefully  engaged, within participatory process activities. The final composition of the involved participants should be flexible and tailor-made to the needs of each region. Lastly, it is also recommended to maintain certain room for manoeuvre in engaging with additional participants on a demand driven topic of discussion.",
    "power4BioExperience": "The POWER4BIO experience",
    "realExamples": {
      "examples": [
        {
          "icon": "LVIV.jpg",
          "title": "Draft Bioeconomy Strategy in Lviv (Ukraine)"
        },
        {
          "icon": "MAZOVIA.jpg",
          "title": "Draft Bioeconomy Strategy in Mazovia (Poland)"
        },
        {
          "icon": "NITRA.jpg",
          "title": "Draft Bioeconomy Strategy in Nitra (Slovakia)"
        },
        {
          "icon": "SOUTH_BOHEMIA.jpg",
          "title": "Draft Bioeconomy Strategy in South Bohemia (Czech Republic)"
        },
        {
          "icon": "SOUTHERN_GREAT_PLAIN.jpg",
          "title": "Draft Bioeconomy Strategy in Southern Great Plain (Hungary)"
        }
      ],
      "paragraph": "Check the summaries of the 5 draft new Bioeconomy strategies developed by Central and Eastern Europe regions of POWER4BIO:",
      "tabTitle": "Real Examples"
    },
    "stepsTitle": "Overview of the steps",
    "subtitle": "Who to involve in this phase?",
    "title": "Phase 3: Development of Bioeconomy strategy"
  }
}
</i18n>

<script>
import VueGallery from "vue-gallery";
import { EventBus } from "@/utils/EventBus";
import { getArrayPiece } from "@/utils/helpers";
import Divider from "@/components/Divider";
import ReadMore from "@/components/ReadMore";
export default {
  name: "Phase3",
  components: {
    Step1: () => import("./Step1"),
    Step2: () => import("./Step2"),
    Step3: () => import("./Step3"),
    Step4: () => import("./Step4"),
    Step5: () => import("./Step5"),
    ReadMore,
    Divider,
    VueGallery
  },
  data() {
    return {
      page: 1,
      steps: 1,
      tab: 0,
      step2Tab: 0,
      faoLink:
        "http://www.fao.org/climate-change/our-work/areas-of-work/bioeconomy/en/",
      scarLink: "https://www.scar-swg-sbgb.eu/the-bioeconomy",
      euCommisionLink: "https://ec.europa.eu/research/bioeconomy/index.cfm",
      footerTabs: [
        { tab: "realExamples.tabTitle" },
        { tab: "notes.tabTitle" }
        // { tab: "opinions.title" }
      ],
      phase3: [
        { component: "Step1" },
        { component: "Step2" },
        { component: "Step3" },
        { component: "Step4" },
        { component: "Step5" }
      ],
      realExamplesFiles: [
        require("@/assets/files/1_3_1_Draft_Bioeconomy_Strategy_Lviv_Region_Ukraine.docx")
          .default,
        require("@/assets/files/1_3_2_Draft_Bioeconomy_Strategy_Mazovia_Region_Poland.docx")
          .default,
        require("@/assets/files/1_3_3_Draft_Bioeconomy_Strategy_Nitra_Region_Slovakia.docx")
          .default,
        require("@/assets/files/1_3_4_Draft_Bioeconomy_Strategy_South_Bohemia_Czech_Republic.docx")
          .default,
        require("@/assets/files/1_3_5_Draft_Bioeconomy_Strategy_Southern_Great_Plain_Hungary.docx")
          .default
      ],
      index: 0,
      images: [
        require("@/assets/icons/regions/LVIV.jpg"),
        require("@/assets/icons/regions/MAZOVIA.jpg"),
        require("@/assets/icons/regions/NITRA.jpg"),
        require("@/assets/icons/regions/SOUTH_BOHEMIA.jpg"),
        require("@/assets/icons/regions/SOUTHERN_GREAT_PLAIN.jpg")
      ]
    };
  },
  computed: {
    orderedList() {
      return Object.values(this.$t("orderedList"));
    },
    examples() {
      return Object.values(this.$t("realExamples.examples"));
    },
    opinions() {
      return Object.values(this.$t("opinions.list"));
    },
    currentOpinions() {
      return getArrayPiece(this.opinions, this.page, 3);
    }
    // step2PolicyAnalysisMaterial() {
    //   return this.$t("step1.policyAnalysis.Materialparagraph").split(":");
    // },
  },
  watch: {
    steps(val) {
      if (window) {
        const url = new URL(window.location);
        url.searchParams.set("step", val);
        window.history.pushState({}, "", url);
      }
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.step) {
      this.steps = Number(query.step);
    }

    EventBus.$on("goToStep", stepNumber => {
      this.steps = stepNumber;
    });
  },
  methods: {
    getChildren(item) {
      return Object.values(item.children);
    },
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";

.phase3 {
  margin-top: 50px;

  .example {
    font-family: $font-family;
  }

  .v-window {
    background-color: $off_white;
  }

  .pagination {
    .v-pagination__item {
      background: $inactive_grey;
      min-width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      margin: 0px 3px;
      box-shadow: none !important;
    }
    .v-pagination__navigation {
      background: $inactive_grey;
      min-width: 18px !important;
      width: 18px !important;
      height: 20px !important;
      font-size: 10px;
      box-shadow: none !important;
    }
    .v-pagination__item .v-pagination__item--active {
      box-shadow: none;
    }
    .mdi-chevron-left {
      font-size: 12px;
    }
    .mdi-chevron-right {
      font-size: 12px;
    }
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 200px;

    .bsat__paragraph {
      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .v-tab {
    font-family: $font-family;
    text-transform: none;
    letter-spacing: normal;
    justify-items: start;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .steps {
    &__title {
      font-family: $font-family;
      color: $light_green;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.6;
      letter-spacing: 0.2px;
      text-align: left;
    }

    &__content {
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid $light_green;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .v-stepper__header {
      background-color: $off_white;
      box-shadow: none;
    }

    .v-stepper__step--active {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $light_green !important;
        border-radius: 50%;
        color: $light_green !important;
      }
    }

    .v-stepper__step--inactive {
      .v-stepper__step__step {
        align-items: center;
        border-radius: 50%;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        width: 36px;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: $off_white !important;
        border: 4px solid $inactive_grey !important;
        border-radius: 50%;
        color: $inactive_grey !important;
      }
    }

    .v-stepper__step__step {
      align-items: center;
      border-radius: 50%;
      display: inline-flex;
      font-size: 16px;
      font-weight: bold;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      width: 36px;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $off_white !important;
      border: 4px solid $inactive_grey !important;
      border-radius: 50%;
      color: $inactive_grey !important;
    }
    .v-stepper__step__step:hover {
      cursor: pointer;
    }
  }
}
</style>
